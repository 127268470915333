const INTEGRATION = 'https://editorial-int.uefa.com';
const PREPROD = 'https://editorial-pre.uefa.com';
const PROD = 'https://editorial.uefa.com';
const LOCAL = PREPROD;

const env = getEnv();
const baseUrl = getEnvProperties();

function getEnv() {
  const qsParams = new URLSearchParams(window.location.search);
  const qsEnv = qsParams.get('env');

  return qsEnv ?? import.meta.env.VITE_APP_ENVIRONMENT;
}

function getEnvProperties() {
  switch (getEnv()) {
    case 'int':
      return INTEGRATION;
    case 'pre':
      return PREPROD;
    case 'prod':
      return PROD;
    default:
      return LOCAL;
  }
}

export { baseUrl, env };
